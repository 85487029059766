<template>
  <el-dialog
    width="1200px"
    @open="init"
    top="5vh"
    :visible.sync="openDialog"
    center
  >
    <div class="list-page">
      <div class="top-btn">
      <el-button size="small" type="primary" @click="toAdmin"
        >添加用户</el-button
      >
    </div>
      <list-filter :value="filterForm" @update="getDadgeIndex" />
      <div class="list-content" v-loading="loading">
        <el-table
          :data="tbody"
          class="thead-light"
          stripe
          style="width: 100%"
          height="400"
        >          
        <template v-for="(th, index) in thead">
            <el-table-column
              v-if="th.type === 'checkbox'"
              :label="th.label"
              :min-width="th.minWidth || ''"
              :key="index"
            >
              <template slot-scope="scope">
                <el-radio v-model="radio" :label="scope.row">&#160;</el-radio>
              </template>
            </el-table-column>
            <el-table-column
              v-else
              :label="th.label"
              :prop="th.prop"
              :width="th.width"
              :fixed="th.fixed"
              :key="index+th.prop"
            >
              <template slot-scope="{ row }">
                <!-- 操作 -->
                <template v-if="th.prop === 'operate'">
                  <el-button
                    size="small"
                    type="text"
                    :disabled="row.account === 'admin'"
                    @click="onUserEdit(row.id)"
                    >编辑</el-button
                  >
                  <el-button
                    size="small"
                    type="text"
                    :disabled="row.account === 'admin'"
                    @click="onDisableEnable(!row.status, row.id)"
                    >{{ row.status ? '禁用' : '启用' }}</el-button
                  >
                  <el-button
                    size="small"
                    type="text"
                    :disabled="row.account === 'admin'"
                    @click="onDelete(row.id)"
                  >
                    删除</el-button
                  >
                </template>
                <!-- 状态 -->
                <span v-else-if="th.prop === 'status'">
                  <ListStatusText
                    :text="row.status ? '激活' : '禁用'"
                    :type="row.status ? 'success' : 'info'"
                  ></ListStatusText>
                </span>
                <!-- 其他 -->
                <span v-else>{{ row[th.prop] | placeholder }}</span>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </div>
      <Pagination
        :total="pageData.total"
        :page="pageData.current_page"
        :change="changPage"
      />
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button style="transform: scale(1.2)" @click="openDialog = false"
        >取消</el-button
      >
      <el-button
        :disabled="JSON.stringify(this.radio) === '{}'"
        style="transform: scale(1.2) translateX(20px)"
        type="primary"
        @click="change"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import listFilter from './ListFilter.vue'
import PopoverInputEdit from '@/base/components/Popover/PopoverInputEdit'
import Pagination from '@/base/components/Default/Pagination'
import ListImage from '@/base/components/List/ListImage'
import ListStatusText from '@/base/components/List/ListStatusText'
import { userList } from '@/modules/venue/api'
export default {
  name: 'DadgeDialog',
  model: {
    prop: 'open',
    event: 'closeDialog',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false, //加载
      thead: [
        {
          label: '勾选',
          minWidth: 60,
          type: 'checkbox',
        },
        { label: 'ID', prop: 'id', width: 80 },
        { label: '用户名', prop: 'nickname', width: 150 },
        { label: '用户角色', prop: 'roles_name', width: 200 },
        { label: '手机号', prop: 'phone', width: 120 },
        { label: '数据授权', prop: 'auth_organization_text', width: 200 },
        { label: '创建时间', prop: 'create_time', width: 180 },
        { label: '状态', prop: 'status' },
      ],
      //筛选对象
      filterForm: {
        create_start_time: -1, // 开始时间
        create_end_time: -1, // 结束时间
        login_start_time: -1,
        login_end_time: -1,
        nickname: '',
        page: 1,
        page_size: 15,
        phone: '',
        email: '',
      },
      pageData: {
        page_size: 15,
      },
      tbody: [],
      radio: {},
    }
  },

  methods: {
    // 获取勋章列表
    getDadgeIndex(pageData) {
      this.tbody = []
      this.pageData = {}
      this.loading = true
      this.radio = {}
      userList(pageData)
        .then((res) => {
          const { data } = res
          // this.exchangeParentList(data.data);
          this.tbody = data.data
          this.pageData = data
          // this.filters[data.tab] = { ...this.filterForm };
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 筛选回调
    ok(e) {
      const pageData = {}
      this.filterForm = { ...this.filterForm, ...e, page: 1 }
      this.getDadgeIndex(this.filterForm)
    },
    // 分页查询
    changPage(e) {
      this.filterForm.page_size = e.page_size
      const pageData = { ...this.filterForm, ...e }
      this.getDadgeIndex(pageData)
    },
    init() {
      this.getDadgeIndex(this.filterForm)
    },
    change() {
      this.openDialog = false
      this.$emit('change', this.radio)
    },
    toAdmin(){
      this.$router.push({
        name:'AdminUserList'
      })
    }
  },

  computed: {
    openDialog: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('closeDialog', val)
      },
    },
  },
  components: {
    listFilter,
    Pagination,
    PopoverInputEdit,
    ListImage,
    ListStatusText,
  },
}
</script>

<style lang="scss" scoped></style>
