<template>
  <LayoutFilter
    :onFilter="ok"
    :onReset="resetForm"
    :toggleCollapseEnable="true"
    :onExport="onExport"
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="templateForm"
      label-width="80px"
      size="medium"
      class="filter-from"
    >
      <div class="filter-item">
        <el-form-item label="场地名称：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="容纳人数：" prop="min_people_number">
          <!--          <el-input v-model="form.keyword" placeholder="请输入"></el-input>-->
          <div class="price-box">
            <el-input v-model="minP" placeholder="最少人数"></el-input>
            <div>-</div>
            <el-form-item prop="max_price">
              <el-input
                v-model="maxP"
                placeholder="最多人数"
              ></el-input>
            </el-form-item>
          </div>
        </el-form-item>
        <el-form-item prop="max_people_number">
          <input v-model="form.max_people_number" type="hidden" />
        </el-form-item>
      </div>

      <div class="filter-item">
        <el-form-item label="状态：" prop="status">
          <el-select v-model="form.status" placeholder="请选择">
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="场地设施：" prop="venue_facility_id">
          <el-select v-model="form.venue_facility_id" placeholder="请选择">
            <el-option
              v-for="item in categoryOption"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <DatePeriodPicker
          label="开放时间："
          start-prop="start_time"
          :start-time.sync="form.start_time"
          end-prop="end_time"
          :end-time.sync="form.end_time"
        />
        <el-form-item prop="start_time" style="width: 0">
          <input v-model="form.start_time" type="hidden" />
        </el-form-item>
        <el-form-item prop="end_time" style="width: 0">
          <input v-model="form.end_time" type="hidden" />
        </el-form-item>
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from '@/base/layout/LayoutFilter'
import DatePeriodPicker from '@/base/components/Form/DatePeriodPicker'
import { getAllFacilities } from '../../api'

export default {
  name: 'GoodsListFilter',
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
  },
  data() {
    return {
      rules: {},
      // 联级选择配置
      props: {
        value: 'id',
        label: 'name',
        expandTrigger: 'hover',
        children: 'position',
      },
      // 商品状态选项
      statusOptions: [
        {
          value: -1,
          label: '全部',
        },
        {
          value: 1,
          label: '开启',
        },
        {
          value: 0,
          label: '关闭',
        },
      ],
      // 分类
      categoryOption: [
        {
          id: '',
          name: '全部',
        },
      ],
    }
  },
  computed: {
    form: {
      get() {
        return this.filterForm
      },
      set(val) {},
    },
    minP: {
      get() {
        return this.form.min_people_number === -1
          ? ''
          : this.form.min_people_number
      },
      set(val) {
        if (!val) this.form.min_people_number = -1
        else this.form.min_people_number = Number(val)
      },
    },
    maxP: {
      get() {
        return this.form.max_people_number === -1
          ? ''
          : this.form.max_people_number
      },
      set(val) {
        if (!val) this.form.max_people_number = -1
        else this.form.max_people_number = Number(val)
      },
    },
  },
  created() {
    this.getCategoryList()
  },
  methods: {
    onExport() {
      // const postData = { ...this.form };
      // return exportGoodsList(postData);
    },
    //重置表单
    resetForm() {
      this.$refs['templateForm'].resetFields()
      this.uploadFilter(this.form)
    },
    //筛选
    ok() {
      this.uploadFilter(this.form)
    },
    // 分类列表
    getCategoryList() {
      getAllFacilities()
        .then(({ data }) => {
          this.categoryOption.push(...data)
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="scss" scoped>
.price-box {
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    margin: 0 5px;
  }
}
</style>
