<template>
  <div class="page flex flex-1">
    <div class="sidebar flex-col">
      <el-input
        size="small"
        v-model="keyword"
        prefix-icon="el-icon-search"
        placeholder="请输入"
        @keyup.enter.native="getOrganizations()"
      />
      <el-scrollbar class="sidebar__content flex-1">
        <el-tree
          v-if="organizations && organizations.length"
          :data="organizations"
          :props="defaultProps"
          :highlight-current="true"
          class="tree"
          node-key="id"
          :current-node-key="
            $route.query.organization_id || layoutForm.organization_id
          "
          :default-expanded-keys="[
            $route.query.organization_id || layoutForm.organization_id,
          ]"
          :default-checked-keys="[
            $route.query.organization_id || layoutForm.organization_id,
          ]"
          @node-click="handleNodeClick"
        ></el-tree>
      </el-scrollbar>
    </div>
    <div class="flex-1">
      <template>
        <list-layout
          ref="venueList"
          :tabArr="tabArr"
          :filter-form="layoutForm"
          :thead="tHead"
          :on-fetch="getList"
          :on-toggle="toggleStatus"
          :on-delete="deleteArr"
          :on-edit="toEdit"
          :on-put-back="onPutBack"
          @command="handleListOperation"
        >
          <template #top>
            <div style="margin-bottom: 20px">
              <el-button type="primary" size="small" @click="toEdit()"
                >添加场地</el-button
              >
            </div>
          </template>
          <template #filter>
            <ListFilter :filter-form="layoutForm" :upload-filter="onFilter" />
          </template>
          <!--          <template #tabletop>-->
          <!--            <el-button type="danger" size="small" @click="deleteArr"-->
          <!--            >批量删除</el-button-->
          <!--            >-->
          <!--&lt;!&ndash;            <el-button&ndash;&gt;-->
          <!--&lt;!&ndash;                    v-if="['deleted'].includes(filterForm.tab)"&ndash;&gt;-->
          <!--&lt;!&ndash;                    type="success"&ndash;&gt;-->
          <!--&lt;!&ndash;                    size="small"&ndash;&gt;-->
          <!--&lt;!&ndash;                    @click="putbackArr"&ndash;&gt;-->
          <!--&lt;!&ndash;            >批量还原</el-button&ndash;&gt;-->
          <!--&lt;!&ndash;            >&ndash;&gt;-->
          <!--          </template>-->
          <!--          <template #operation="{row}">-->
          <!--            <el-button type="text" @click="toEdit(row)">编辑</el-button>-->
          <!--&lt;!&ndash;            <el-button type="text" @click="rollbackBackup(row)">还原</el-button>&ndash;&gt;-->
          <!--            <el-button type="text" @click="toDel(row)">删除</el-button>-->
          <!--          </template>-->
          <template #item_name="{ row }">
            <el-link type="primary" :underline="false" @click="toEdit(row)">{{
              row.name
            }}</el-link>
          </template>
          <template #item_reserve_times="{ row }">
            <template v-for="rt in row.reserve_times">
              <template v-if="rt.mode === 'week'">
                <template v-for="rtw in rt.weektimeData">
                  {{
                    [
                      '星期日',
                      '星期一',
                      '星期二',
                      '星期三',
                      '星期四',
                      '星期五',
                      '星期六',
                    ][rtw.day]
                  }}：{{ parseToTime(rtw.begin) }}-{{ parseToTime(rtw.end) }}
                </template>
                <span v-if="!rt.weektimeData.length">{{
                  '' | placeholder
                }}</span>
              </template>
              <template v-if="rt.mode === 'day'">
                <template v-for="rtw in rt.daytimeData">
                  {{ parseToTime(rtw.begin) }}-{{ parseToTime(rtw.end) }}
                </template>
                <span v-if="!rt.daytimeData.length">{{
                  '' | placeholder
                }}</span>
              </template>
            </template>
            <span v-if="!row.reserve_times || !row.reserve_times.length">{{
              '' | placeholder
            }}</span>
          </template>
        </list-layout>
      </template>
    </div>
    <VenueAdminSelector  v-model="venueAdminSelectorShow" @change="handleChangeVenueAdmin" />
  </div>
</template>
<script>
import ListLayout from '../../../base/layout/ListLayout'
import {
  changeStatus,
  getOrgList,
  getVenueList,
  removeVenue,
  softDelete,
  putBack,
  changeAdminUser,
} from '../api'
import ListFilter from '../components/list/ListFilter'
import { parseToTime } from '../js/time-tool'
import  VenueAdminSelector  from '../components/venue-admin-selector'
export default {
  components: { ListFilter, ListLayout,VenueAdminSelector },
  data() {
    const moreHandleList = [
      { name: '场地管理员', command: 'VenueAdmin' },
  ]
    return {
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      /**
       "id": "b9djDn1r",
       "name": "测试场地一",
       "people_number": 1000,
       "status": 0,
       "create_step": 2,
       "facility_names": "大电视",
       "organization_name": "测试测试"
       */
      tHead: [
        {
          type: 'selection',
        },
        {
          label: '场地名称',
          prop: 'name',
          type: 'slot',
          minWidth: 220,
        },
        {
          label: '所属组织',
          prop: 'organization_name',
          minWidth: 220,
        },
        {
          label: '管理员',
          prop: 'admin_user_name',
          minWidth: 220,
        },
        {
          label: '管理员手机号',
          prop: 'phone',
          minWidth: 120,
        },
        // {
        //   label: 'TODO：分组',
        //   prop: 'organization_name',
        // },
        {
          label: '场地分类',
          prop: 'category_name',
          minWidth: 120,
        },
        {
          label: '容纳人数',
          prop: 'people_number',
        },
        {
          label: '场地设施',
          prop: 'facility_names',
          minWidth: 250,
        },
        {
          label: '权限',
          prop: 'reserve_text',
          minWidth: 200,
        },
        {
          label: '开放时间',
          prop: 'reserve_time_text',
          // type: 'slot'
        },
        {
          label: '状态',
          prop: 'status',
          type: 'checkbox',
        },
        {
          label: '操作',
          type: 'operation',
          minWidth: 200,
          operations: [
            {
              command: 'detail',
              text: '详情',
              visible: (r, q) => q.tab !== 'deleted',
            },
            {
              command: 'edit',
              visible: (r, q) => q.tab !== 'deleted',
            },
            { command: 'putback', visible: (r, q) => q.tab === 'deleted' },
            {
              command: 'delete',
            },
            {
              command: 'more', text: '更多', operations: moreHandleList 
            }
          ],
        },
      ],
      layoutForm: {
        status: -1, // 预定状态
        venue_facility_id: '', // 设施 ID
        keyword: '',
        page: 1,
        page_size: 15,
        order_by: 'id',
        is_desc: 0,
        organization_id: this.$route.query.organization_id || -1,
        start_time: -1,
        end_time: -1,
        tab: 'normal',
        min_people_number: -1,
        max_people_number: -1,
      },
      tabArr: [
        { label: '全部', name: 'normal' },
        { label: '回收站', name: 'deleted' },
      ],
      keyword: '',
      // id、name、children
      organizations: [
        {
          id: -1,
          name: '全部',
        },
      ], // null：未请求，[]：请求完成数据为空，其他：有数据
      venueAdminSelectorShow:false,
      venueId:'',
    }
  },
  watch: {
    'layoutForm.organization_id'(val) {
      if (val) this.refreshList()
    },
  },
  created() {
    this.getOrganizations()
  },
  methods: {
    parseToTime,
    deleteArr(ids, is) {
      if (is) {
        removeVenue(ids)
          .then(({ data, msg }) => {
            this.$message.success(msg)
            this.refreshList()
          })
          .catch(() => {
            this.refreshList()
          })
      } else {
        softDelete(ids)
          .then(({ data, msg }) => {
            this.$message.success(msg)
            this.refreshList()
          })
          .catch(() => {
            this.refreshList()
          })
      }
    },
    onPutBack(ids) {
      return putBack(ids);
    },
    toEdit(r) {
      if (r) {
        this.$router.push({
          name: 'EditVenue',
          params: { id: r.id, step: r.create_step },
        })
      } else
        this.$router.push({
          name: 'AddVenue',
        })
    },
    toInfo(r) {
      this.$router.push({
        name: 'VenueDetailInfo',
        params: { id: r.id },
      })
    },
    toggleStatus({ id, status }) {
      return changeStatus({
        id,
        status,
      })
    },
    // 刷新列表
    refreshList() {
      this.$refs.venueList?.getList({ page: 1 })
    },
    onFilter(query) {
      this.$refs.venueList?.getList(query)
    },
    handleNodeClick(data) {
      console.log(data)
      this.layoutForm.organization_id = data.id
      this.$router.replace({
        name: 'VenueList',
        query: {
          organization_id: data.id,
        },
      })
    },
    getList(q) { 
      let organization_id = this.$route.query.organization_id || q.organization_id || ''
      // organization_id  为-1 全部展示
      let is_show_all 
      if(organization_id ===-1){
        is_show_all = 1
      }else{
        is_show_all = 0
      }
      return getVenueList({
        ...q,
        organization_id,
        is_show_all 
      })
    },
    getOrganizations() {
      getOrgList({
        keyword: this.keyword,
      })
        .then(({ data }) => {
          // console.log(data)
          this.organizations =[...this.organizations ,...data] 
          // if (data.length && data[0].id) {
          //   console.log(data[0].id)
          //   this.layoutForm.organization_id = data[0].id
          //   this.organizations = data
          // } else {
          //   this.organizations = data
          // }
        })
        .catch((err) => {})
    },
    handleChangeVenueAdmin(value){
      this.venueAdminSelectorShow = false
      console.log(value)
      changeAdminUser({venue_id:this.venueId,admin_user_id:value.id}).then(res=>{
        this.$message.success('修改成功')
      this.refreshList()
      })
    },
    addItem() {},
    handleListOperation({ command, row }) {
      if (command === 'detail') {
        this.toInfo(row)
      }else if (command === 'VenueAdmin'){
       this.venueAdminSelectorShow =true
       this.venueId = row.id
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.sidebar {
  width: 200px;
  border-right: 1px solid #e4e7ed;
  padding-right: 29px;
  padding-left: 8px;
  margin-right: $space;
  .sidebar__content {
    margin-top: 20px;
  }
}

.page {
  ::v-deep .layout-filter .layout-filter-collapse {
    width: fit-content;
  }
  ::v-deep .tree {
    .el-tree-node__content {
      height: 38px;
    }
    .el-tree-node__label {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
