<template>
  <layout-filter :onFilter="doFilter" :onReset="resetForm" :toggleCollapseEnable="false">
    <el-form :model="value" :rules="rules" class="filter-from" ref="userListFilterForm" label-width="70px" size="medium">
      <div class="filter-item">
        <el-form-item label="用户名：" prop="nickname">
          <el-input v-model="value.nickname" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="手机号码：" prop="phone">
          <el-input v-model="value.phone" placeholder="请输入"></el-input>
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item label="创建时间：">
          <DatePeriodPicker start-prop="create_start_time" :start-time.sync="value.create_start_time"
            end-prop="create_end_time" :end-time.sync="value.create_end_time" />
        </el-form-item>
      </div>
    </el-form>
   
  </layout-filter>
</template>

<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";

export default {
  name: "ListFilter",
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rules: {},
    };
  },
  methods: {
    doFilter: function () {
      this.$emit("update", this.value);
    },
    // 重置表单
    resetForm() {
      this.$refs["userListFilterForm"].resetFields();
      this.doFilter()
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
